import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'

const Button = styled.button`
  ${tw`
    inline-block
    no-underline
    h-full
  `}
`

const ButtonText = styled.span`
  ${tw`
    uppercase
    leading-tight
    tracking-wide
    text-black
    text-center
    text-sm
    font-sans
    font-black
    relative
    z-20
  `}

  ${(props) =>
    props.largeArrow
      ? tw`px-4 py-2 flex items-center`
      : tw`px-4 py-3 lg:px-7 block`}
`

const RhombusButton = ({ children, colorClass }) => {
  return (
    <Button className={`${colorClass}`}>
      <ButtonText>{children}</ButtonText>
    </Button>
  )
}

export default RhombusButton
