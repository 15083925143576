import React from 'react'

const Twitter = ({ url }) => (
  <svg viewBox="0 0 26 21" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Team" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="team-v1-r0" transform="translate(-206.000000, -1613.000000)" className="fill-current" fillRule="nonzero">
        <g id="content" transform="translate(206.000000, 699.000000)">
          <g id="Stacked-Group">
            <g id="Team">
              <g id="entry" transform="translate(0.000000, 284.219479)">
                <g id="social" transform="translate(0.000000, 629.780521)">
                  <g id="twitter">
                    <path d="M25.9090245,2.53079097 C24.9684505,2.93083252 23.9562037,3.2006742 22.8940978,3.32143209 C23.9785365,2.70024957 24.8100435,1.71629645 25.2021657,0.54250996 C24.1878415,1.11847039 23.0649696,1.53640199 21.8688668,1.76201549 C20.911673,0.786013509 19.5472953,0.1757638 18.0374948,0.1757638 C15.1389062,0.1757638 12.7887695,2.42444453 12.7887695,5.19839719 C12.7887695,5.59148149 12.8349932,5.97462687 12.9248437,6.34286387 C8.56215895,6.13315265 4.69391179,4.13393882 2.10486613,1.09511393 C1.65301664,1.83655739 1.39437176,2.69925567 1.39437176,3.62059359 C1.39437176,5.36288632 2.3209229,6.9004373 3.72944684,7.80090348 C2.8693747,7.77456534 2.05968118,7.54845491 1.35178364,7.17226677 C1.35126428,7.1931385 1.35126428,7.21450718 1.35126428,7.23587586 C1.35126428,9.66892356 3.16073972,11.6984511 5.56229381,12.1606109 C5.12187039,12.2749085 4.65807545,12.3365298 4.17921886,12.3365298 C3.84059143,12.3365298 3.51183197,12.3052222 3.19138239,12.2460856 C3.85980801,14.2413239 5.79808653,15.6938971 8.09472844,15.7341497 C6.29823718,17.0813703 4.03535414,17.8844351 1.57563092,17.8844351 C1.15234663,17.8844351 0.734256003,17.8605817 0.323436522,17.8138688 C2.64708552,19.2396069 5.40596427,20.0705006 8.36999307,20.0705006 C18.0255493,20.0705006 23.3049172,12.417532 23.3049172,5.78032098 C23.3049172,5.56265862 23.3002429,5.34549321 23.290375,5.12981864 C24.3156059,4.42316141 25.2058013,3.53810055 25.9090245,2.53079097 Z" id="Path">
                    </path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Twitter
