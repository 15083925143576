import React from 'react'
import { Link } from 'gatsby'

/**
 * Given a url/route, returns either an Anchor tag or a
 * Gatsby Link
 * @param {string} to The route to link to
 */
const SmartLink = ({ to, children, ...rest }) => {
  const external =
    to && (to.search(/^https?:\/\//) !== -1 || to.search('mailto:') !== -1)

  if (external) {
    return to.search('ecosystem.algorand.com') !== -1 ? (
      <a target="_blank" href={to} {...rest}>
        {children}
      </a>
    ) : (
      <a
        target={
          to.search('algorand.com') !== -1 ||
          to.search('algorandtechnologies.com') !== -1 ||
          to.search('mailto:') !== -1
            ? null
            : '_blank'
        }
        href={to}
        {...rest}
      >
        {children}
      </a>
    )
  } else {
    return (
      <Link to={to} {...rest}>
        {children}
      </Link>
    )
  }
}

export default SmartLink
