const locales = {
  'en-us': {
    default: true,
    path: '',
    locale: 'en-us',
    siteLanguage: 'en',
    ogLang: 'en_US',
    text: 'ENG',
  },
  'de-de': {
    path: '/de',
    locale: 'de-de',
    siteLanguage: 'de',
    ogLang: 'de_DE',
    text: 'GER',
  },
  'zh-cn': {
    path: '/zh',
    locale: 'zh-cn',
    siteLanguage: 'zh',
    ogLang: 'zh_CN',
    text: 'CHI',
  },
  'ko-kr': {
    path: '/ko',
    locale: 'ko-kr',
    siteLanguage: 'ko',
    ogLang: 'ko_KR',
    text: 'KOR',
  },
  'pt-pt': {
    path: '/pt',
    locale: 'pt-pt',
    siteLanguage: 'pt',
    ogLang: 'pt_PT',
    text: 'POR',
  },
  'es-es': {
    path: '/es',
    locale: 'es-es',
    siteLanguage: 'es',
    ogLang: 'es_ES',
    text: 'SPA',
  },
  tr: {
    path: '/tr',
    locale: 'tr',
    siteLanguage: 'tr',
    ogLang: 'tr',
    text: 'TUR',
  },
  'it-it': {
    path: '/it',
    locale: 'it-it',
    siteLanguage: 'it',
    ogLang: 'it_IT',
    text: 'ITA',
  },
}
function getLocales() {
  return locales
}

function getLocale(locale) {
  return locales[locale]
}

function localizePath(locale, path) {
  // Don't localize absolute urls
  if (path.search('http') === 0) {
    return path
  }

  const theLocale = getLocale(locale)
  // add initial leading / to path
  if (path.search('/') !== 0) {
    path = '/' + path
  }
  return theLocale ? `${theLocale.path}${path}` : path
}

module.exports = {
  getLocale,
  getLocales,
  localizePath,
}
