const isExternalLink = (link) => {
  if (typeof window === 'undefined') return false

  return (
    link.search(/^https?:\/\//) !== -1 &&
    !link.includes(window.location.hostname)
  )
}

export default isExternalLink
