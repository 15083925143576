import React, { useEffect, useRef } from 'react'
import t from 'prop-types'
import ReactModal from 'react-modal'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock'

const ExternalUrlModal = ({ isOpen, setIsOpen, url }) => {
  ReactModal.setAppElement('#___gatsby')
  const modalRef = useRef()

  useEffect(() => {
    const modalElement = modalRef.current

    if (isOpen) {
      disableBodyScroll(modalElement)
    } else {
      enableBodyScroll(modalElement)
    }
    return () => {
      clearAllBodyScrollLocks()
    }
  }, [isOpen])

  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel={'Alert: External Url Redirect Notice'}
      aria={{
        labelledby: 'modalExternalUrlLabel',
        describedby: 'modalExternalUrlDescription',
      }}
      className="w-full mx-auto bg-white pt-8 lg:pt-10 pb-80px px-2 md:px-4 h-screen md:h-auto"
      style={{
        content: {
          maxWidth: '646px',
        },
      }}
      ref={modalRef}
    >
      <div className="text-center">
        <h2
          id="modalExternalUrlLabel"
          className="text-2xl lg:text-3xl tracking-wide"
        >
          You’re now leaving <br /> Algorand Technologies.
        </h2>
        <p
          id="modalExternalUrlDescription"
          className="text-base md:text-lg font-medium mt-3"
        >
          Come back to our site soon and learn more.
        </p>
        {url && (
          <a
            href={url}
            className="block w-full max-w-1/2 bg-green-1 text-lg font-semibold leading-relaxed text-black mx-auto"
            style={{
              marginTop: '57px',
              paddingTop: '18px',
              paddingBottom: '18px',
            }}
          >
            Proceed
          </a>
        )}
        <button
          onClick={() => setIsOpen(false)}
          className="block w-full max-w-1/2 text-lg font-semibold leading-relaxed underline text-black mx-auto"
          style={{ paddingTop: '13px', paddingBottom: '13px' }}
        >
          Cancel
        </button>
      </div>
    </ReactModal>
  )
}

ExternalUrlModal.propTypes = {
  isOpen: t.bool,
  setIsOpen: t.func,
  url: t.string,
}

ExternalUrlModal.defaultProps = {}

export default ExternalUrlModal
