import React from 'react'
import classNames from 'classnames'
import styled from 'styled-components'
import { getCookie, setCookie } from 'tiny-cookie'
import ActionButton from '../Buttons/ActionButton'
import SmartLink from '../SmartLink'
import Close from '../../Shapes/Close'

const buttonWrapClasses = `
  block
  flex-no-shrink
`

const TextWrap = styled.div``

const CloseWrap = styled.div`
  position: absolute;
  top: 24px;
  right: 32px;
  cursor: pointer;
`

const wrapClasses = `
  notification-bar
  relative
  bg-blue-purple
  pt-4
  pb-7
  lg:py-5
  px-4
`

class NotificationBar extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      closed: false
    }
    this.handleClose = this.handleClose.bind(this)
  }

  handleClose () {
    this.setState({
      closed: true
    })
    setCookie('notification-bar-seen', 'true', { expires: '1M' })
  }

  componentDidMount () {
    const closed = getCookie('notification-bar-seen')
    this.setState({
      closed: closed
    })
  }

  render () {
    const { preMessage, message, buttons, dismissible = true } = this.props
    const { closed } = this.state

    return (
      <div className={ classNames(wrapClasses, { 'hidden': closed && dismissible })}>
        {dismissible && (
          <CloseWrap onClick={ this.handleClose }>
            <Close></Close>
          </CloseWrap>
        )
        }
        <div className="lg:flex items-center justify-center">
          <div className="lg:flex items-center">
            { preMessage &&
            <div class="rhombus rhombus--black-transp-light h-full lg:mr-2 lg:mb-0 mb-1 max-w-xs">
              <div className="text-white text-lg font-bold font-sans relative z-20 leading-tight text-center">
                { preMessage }
              </div>
            </div>
            }
            <TextWrap className="text-white text-lg font-sans font-bold mb-2 lg:mb-0 pr-4 lg:pr-0 pt-1 lg:max-w-lg">
              { message }
            </TextWrap>

            <div className="lg:flex flex-no-shrink">
              { buttons.map((button, index) => (
                <SmartLink to={ button.url } key={ `notification-button-${ index }`} className={ classNames(buttonWrapClasses, {
                  'lg:ml-3 mt-2 lg:mt-0': index > 0,
                  'lg:ml-5': index === 0
                }) }>
                  <ActionButton
                    rounded
                    fullOnMobile={ true }
                    transparentBlackBg={ true }
                    largeArrow={ true }
                  >
                    { button.text }
                  </ActionButton>
                </SmartLink>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default NotificationBar
