import React from 'react'
import classNames from 'classnames'
import styled from 'styled-components'
import tw from 'tailwind.macro'

const Button = styled.button`
  ${ tw`
    inline-block
    no-underline
    h-full
  ` }

  ${ props => props.fullOnMobile && tw`w-full lg:w-auto` }
  ${ props => props.forceFill && tw`w-full` }
  ${ props => props.transparentBlackBg ? tw`bg-black-trans-light` : tw`bg-teal` }
`

const ButtonText = styled.span`
  ${ tw`
    uppercase
    leading-tight
    tracking-wide
    text-white
    text-center
    text-sm
    font-sans
    font-black
    relative
    z-20
  ` }

  ${ props => props.largeArrow ? tw`px-4 py-2 flex items-center` : tw`px-4 py-3 lg:px-7 block` }
`

const ActionButton = ({ children, rounded, fullOnMobile, forceFill, transparentBlackBg, largeArrow, rhombusButton }) => {
  return (
    <Button
      fullOnMobile={fullOnMobile}
      forceFill={forceFill}
      transparentBlackBg={transparentBlackBg}
      largeArrow={ largeArrow }
      className={ rhombusButton && 'rhombus rhombus--purple-to-orange-horizontal', rounded && 'rounded-full' }
    >
      <ButtonText largeArrow={ largeArrow }>
        { children }
        {largeArrow && <div className="font-thin text-4xl ml-auto lg:ml-1 leading-none"> →</div> }
      </ButtonText>
    </Button>
  )
}

export default ActionButton
