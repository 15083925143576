import React from 'react'

const Medium = ({ innerFill }) => {
  const fill = innerFill || '#242424'
  return (
    <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><rect className="fill-current" width="40" height="40" rx="3"/><path d="M10.288 13.542a1.12 1.12 0 0 0-.32-.898L7.593 9.55v-.46h7.378l5.704 13.513L25.691 9.09h7.036v.46l-2.031 2.104a.666.666 0 0 0-.226.614V27.73a.666.666 0 0 0 .226.614l1.984 2.104v.46h-9.983v-.46l2.058-2.155c.2-.216.2-.284.2-.614V15.185l-5.72 15.684h-.774l-6.652-15.684v10.511c-.058.444.079.887.368 1.205l2.674 3.502v.46H7.273v-.454L9.946 26.9c.284-.318.416-.767.342-1.205V13.542z" fill={ fill } fillRule="nonzero"/></g></svg>
  )
}

export default Medium
