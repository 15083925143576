import React from 'react'
import { PreviewStoreProvider } from 'gatsby-source-prismic'
import Layout from './src/components/Layouts/Global/Layout'

const wrapPageElement = ({ element, props: { pageContext } }) => {
  return (
    <PreviewStoreProvider>
      <Layout locale={pageContext.locale}>{element}</Layout>
    </PreviewStoreProvider>
  )
}

export default wrapPageElement
