import React from 'react'

const Telegram = ({ innerFill }) => {
  const fill = innerFill || '#242424'
  return (
    <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><circle className="fill-current" fillRule="nonzero" cx="20" cy="20" r="20"/><path d="M20.655 24.78c-.77.75-1.534 1.488-2.29 2.231-.265.26-.562.405-.941.385-.26-.014-.404-.128-.484-.374-.58-1.798-1.168-3.593-1.744-5.393-.056-.175-.139-.26-.316-.314a374.997 374.997 0 0 1-4.088-1.276 2.325 2.325 0 0 1-.601-.277c-.28-.19-.32-.498-.07-.72.231-.206.508-.384.795-.498 1.669-.661 3.347-1.301 5.022-1.947l12.754-4.923c.808-.312 1.43.128 1.345 1.002-.053.56-.198 1.11-.315 1.661-.923 4.359-1.847 8.718-2.775 13.076-.22 1.035-.947 1.305-1.799.677-1.426-1.051-2.851-2.106-4.276-3.159-.068-.05-.14-.097-.217-.151zm-3.201 1.323l.048-.013c.009-.04.022-.08.025-.121.092-.988.189-1.976.268-2.965a.726.726 0 0 1 .26-.526c1.314-1.175 2.62-2.358 3.93-3.539 1.45-1.307 2.899-2.613 4.343-3.925.09-.081.127-.219.188-.33-.136-.016-.282-.07-.405-.038-.169.043-.328.141-.479.236-3.198 2.014-6.394 4.033-9.595 6.043-.181.114-.215.21-.146.41.236.677.45 1.361.673 2.044l.89 2.724z" fill={ fill }/></g></svg>
  )
}

export default Telegram
