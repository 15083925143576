import Twitter from '../../Shapes/Twitter'
import LinkedIn from '../../Shapes/LinkedIn'
import Telegram from '../../Shapes/Telegram'
import Facebook from '../../Shapes/Facebook'
import Medium from '../../Shapes/Medium'
import YouTube from '../../Shapes/YouTube'
import Discourse from '../../Shapes/Discourse'
import Reddit from '../../Shapes/Reddit'
import Discord from '../../Shapes/Discord'
import { getLocales } from '../../../../config/i18n'

const locales = getLocales()
export class GlobalTranslations {
  constructor (translations, locale) {
    this.translations = translations
    this.locale = locale
    this.defaultTranslations = this.translations.allPrismicGlobalNavigation.nodes.find(doc => {
      return doc.lang === 'en-us'
    })
    this.globalTranslations = this.translations.allPrismicGlobalNavigation.nodes.find(doc => {
      return doc.lang === this.locale
    })
    this.defaultTranslationsGeneral = this.translations.allPrismicGlobal.nodes.find(doc => {
      return doc.lang === 'en-us'
    })
    this.globalTranslationsGeneral = this.translations.allPrismicGlobal.nodes.find(doc => {
      return doc.lang === this.locale
    })
  }
  get (value) {
    return this.hasTranslationValue(value, this.globalTranslations) ? this.globalTranslations.data[value] : this.defaultTranslations.data[value]
  }
  getGlobalField (value) {
    return this.hasTranslationValue(value, this.globalTranslationsGeneral) ? this.globalTranslationsGeneral.data[value] : this.defaultTranslationsGeneral.data[value]
  }

  hasTranslationValue (key, dataSet) {
    if (dataSet && dataSet.data[key]) {
      if (Array.isArray(dataSet.data[key])) {
        return dataSet.data[key].length > 0
      }

      return true
    }

    return false
  }
}

export const makeHeaderProps = (translations, locale) => {
  const path = (locale && locales[locale] && locales[locale].path) ? locales[locale].path : '/'
  const localTrans = new GlobalTranslations(translations, locale)
  const menuSections = localTrans.get('body')

  return {
    homeURL: `${ path }`,
    locale,
    navItems:
      menuSections
        .map((navItemSection, index) => {
          const { primary, items } = navItemSection
          return {
            text: primary.menu_label.text,
            route: primary.menu_link.url,
            highlight: primary.menu_highlight === 'Yes',
            is_green: primary.is_green,
            megaNav: {
              menuSlug: primary.menu_link.url,
              pageLinks: items.map(item => ({
                text: item.sub_menu_item_label.text,
                route: item.sub_menu_item_link.url
              }))
            }
          }
        }),
    notification: {
      preMessage: localTrans.getGlobalField('notification_label').text,
      message: localTrans.getGlobalField('notification_bar_message').text,
      buttons: [
        {
          text: localTrans.getGlobalField('notification_bar_link_text').text,
          url: localTrans.getGlobalField('notification_bar_link').url
        }
      ]
    }
  }
}

export const makeFooterProps = (translations, locale) => {
  const path = (locale && locales[locale] && locales[locale].path) ? `/${ locales[locale].path }` : ''
  const localTrans = new GlobalTranslations(translations, locale)
  const follow = localTrans.get('follow')[0]

  return {
    columns: localTrans.get('footer_body').map(item => ({
      heading: item.primary.footer_menu_section_title.text,
      links: item.items.map(link => ({
        text: link.footer_menu_section_item_title.text,
        route: link.footer_menu_section_item_link.url,
        external: link.footer_menu_section_item_link.target === '_blank'
      }))
    })),
    official: {
      text: localTrans.get('algorand_official_sites_text').text,
      route: localTrans.get('algorand_official_sites_link').url,
    },
    follow: {
      heading: follow.follow_us_text.text,
      icons: [
        {
          shape: Twitter,
          url: follow.twitter_url.url
        },
        {
          shape: LinkedIn,
          url: follow.linkedin_url.url
        },
        {
          shape: Telegram,
          url: follow.telegram_url.url
        },
        {
          shape: Facebook,
          url: follow.facebook_url.url
        },
        {
          shape: Medium,
          url: follow.medium_url.url
        },
        {
          shape: YouTube,
          url: follow.youtube_url.url
        },
        {
          shape: Discourse,
          url: follow.algorand_community_url.url
        },
        {
          shape: Reddit,
          url: follow.reddit_url.url
        },
        {
          shape: Discord,
          url: follow.discord_url.url
        }
      ]
    },
    copyrightText: localTrans.get('copyright_text').text,
    homeURL: `/${ path }`
  }
}
