import React from 'react'
import classNames from 'classnames'
import SmartLink from '../../General/SmartLink'
import styled from 'styled-components'
import ExternalLinkArrow from '../../Shapes/ExternalLinkArrow'
import isExternalLink from '../../../utils/isExternalLink'

const pageLinkClasses = `
  block
  font-sans
  uppercase
  text-sm
  leading-tight
  text-white
  no-underline
`

const HoverEffect = styled.div`
  position: relative;

  :hover:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 2px;
    background-color: #76f935;
  }

  a {
    padding: 2px;
  }
`

const MegaNavLayout = ({ menuSlug, pageLinks }) => {
  return (
    <div className="meganav-shadow bg-black flex items-stretch">
      <div className={classNames('flex-no-shrink flex flex-col w-full')}>
        {pageLinks && (
          <div className="pt-5 px-4 pb-4">
            {pageLinks.map((pageLink, i) => {
              return (
                <HoverEffect className="-ml-2 pl-1">
                  <SmartLink
                    key={`${menuSlug}-page-link-${i}`}
                    className={classNames(pageLinkClasses, {
                      'mb-4': pageLinks.length > i + 1,
                    })}
                    to={pageLink.route}
                  >
                    {pageLink.text}
                    {isExternalLink(pageLink.route) && (
                      <ExternalLinkArrow className="flex-no-shrink ml-0.5 -mb-1" />
                    )}
                  </SmartLink>
                </HoverEffect>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export default MegaNavLayout
