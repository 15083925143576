import React, { Component } from 'react'
import tw from 'tailwind.macro'
import styled from 'styled-components'
import cx from 'classnames'
import { Link } from 'gatsby'

// components
import AlgorandFullLogo from '../../Shapes/AlgorandFullLogo'
import MenuTrigger from '../../Shapes/MenuTrigger'
import CloseBtn from '../../Shapes/X'
import MegaNav from '../../General/MegaNav'
import NotificationBar from '../../General/NotificationBar'
import RhombusButton from '../../General/Buttons/RhombusButton'
import SmartLink from '../../General/SmartLink'
import ExternalLinkArrow from '../../Shapes/ExternalLinkArrow'
import DownArrow from '../../Shapes/DownArrow'
import isExternalLink from '../../../utils/isExternalLink'

const headerWrapClasses = `
  bg-black
  header-shadow
  relative
  z-30
`

const headerInteriorClasses = `
  flex
  justify-between
  px-2
  py-2
  max-w-5xl
  mx-auto
  lg:py-0
`

const navClasses = `
  relative
  hidden
  mx-auto
  lg:flex
  justify-center
  h-90px
`

const linkClasses = `
  flex
  h-full
  items-center
  font-sans
  text-sm
  uppercase
  tracking-wide
  no-underline
`

const mobileNavContainerClasses = `
  lg:hidden
`

const mobileNavClasses = `
  p-2
  flex
  flex-col
  mobile-navigation
`

const mobileLinkClasses = `
  text-base
  font-bold
  font-sans
  uppercase
  pt-2
  pb-1
  text-grey
  no-underline
  block
`

const mobileSubLinkClasses = `
  text-sm
  font-light
  font-sans
  uppercase
  py-1
  pl-2
  text-white
  no-underline
  block
`

const MobileNavItem = styled.li`
  a:visited {
    ${tw`
      text-light-grey-2
    `}
  }
`

const LinkWrap = styled.div`
  ${tw`
    overflow-visible
  `}

  .mega-nav__wrap {
    top: 100%;

    ${tw`
      hidden
      absolute
      bg-white
    `}
  }

  .nav-link__item {
    max-width: 140px;
  }

  .nav-link__item--big {
    max-width: 155px;
  }

  &:hover {
    .nav-link__item {
      border-color: #76f935;
    }

    .mega-nav__wrap {
      min-width: 250px;
      ${tw`
        block
      `}
    }
  }
`

const Details = styled.details`
  summary::-webkit-details-marker {
    display: none;
  }
`

const MobileSubNav = ({ pageLinks }, toggle) => {
  return (
    <ul className="list-reset mb-3">
      {pageLinks &&
        pageLinks.map((link, i) => {
          return link.highlight ? (
            <MobileNavItem onClick={toggle} key={i}>
              <SmartLink
                key={`mobile-nav-item-mobile-${i}`}
                to={link.route}
                className="self-center"
              >
                <RhombusButton
                  colorClass={link.is_green ? 'light-green' : null}
                >
                  {link.text}
                </RhombusButton>
              </SmartLink>
            </MobileNavItem>
          ) : (
            <MobileNavItem onClick={toggle} key={i}>
              <SmartLink
                key={`mobile-nav-item-mobile-${i}`}
                className={cx(mobileSubLinkClasses)}
                activeClassName="text-green-3"
                partiallyActive={true}
                to={link.route}
              >
                {link.text}
                {isExternalLink(link.route) && (
                  <ExternalLinkArrow className="flex-no-shrink ml-0.5 -mb-1" />
                )}
              </SmartLink>
            </MobileNavItem>
          )
        })}
    </ul>
  )
}

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobileNavOpen: false,
    }
    this.toggleMobileNav = this.toggleMobileNav.bind(this)
  }

  toggleMobileNav = (e) => {
    this.setState((state) => ({ isMobileNavOpen: !state.isMobileNavOpen }))
  }

  render() {
    const { homeURL, navItems, notification } = this.props

    return (
      <>
        {notification.message && <NotificationBar {...notification} />}
        <header className={headerWrapClasses} id={`site-header`}>
          <div className={headerInteriorClasses}>
            <Link
              className="text-white flex items-center mr-2 md:mr-0"
              to={homeURL}
            >
              <AlgorandFullLogo />
            </Link>
            {navItems && (
              <nav className={navClasses}>
                {navItems.map((navItem, i) => (
                  <LinkWrap key={`nav-item-${i}`}>
                    {navItem.route ? (
                      <SmartLink
                        className={cx(linkClasses, {
                          'nav-link__item': true,
                          'text-white font-bold border-b-2 px-2 border-black cursor-pointer': true,
                        })}
                        activeClassName="border-green-1"
                        partiallyActive={true}
                        to={navItem.route}
                      >
                        <div className="relative z-20 text-center text-white">
                          {navItem.text}
                        </div>
                      </SmartLink>
                    ) : (
                      <div
                        className={cx(linkClasses, {
                          'nav-link__item': true,
                          'text-white font-bold border-b-2 px-2 border-black cursor-pointer': true,
                        })}
                        activeClassName="border-magenta"
                        partiallyActive={true}
                      >
                        <div className="relative z-20 text-center">
                          {navItem.text}
                        </div>
                      </div>
                    )}
                    {navItem.megaNav.pageLinks.length > 0 && (
                      <div className="mega-nav__wrap">
                        <MegaNav {...navItem.megaNav} />
                      </div>
                    )}
                  </LinkWrap>
                ))}
              </nav>
            )}
            <div className={cx('flex items-center justify-end w-16 lg:hidden')}>
              <button
                className={cx('text-white cursor-pointer')}
                onClick={this.toggleMobileNav}
              >
                {this.state.isMobileNavOpen ? <CloseBtn /> : <MenuTrigger />}
              </button>
            </div>
          </div>
          <div
            className={cx(mobileNavContainerClasses, {
              block: this.state.isMobileNavOpen,
              hidden: !this.state.isMobileNavOpen,
            })}
          >
            {navItems && (
              <nav className={mobileNavClasses}>
                {navItems.map((navItem, i) => {
                  return navItem.route ? (
                    <div key={i}>
                      <SmartLink
                        key={`mobile-nav-item-${i}`}
                        to={navItem.route}
                        className={`self-center ${mobileLinkClasses}`}
                        onClick={this.toggleMobileNav}
                      >
                        {navItem.text}
                      </SmartLink>
                    </div>
                  ) : (
                    <Details key={i}>
                      <summary className={cx(mobileLinkClasses, 'flex')}>
                        {navItem.text}
                        <span className="ml-1">
                          <DownArrow />
                        </span>
                      </summary>
                      {navItem &&
                        navItem.megaNav &&
                        MobileSubNav(navItem.megaNav, this.toggleMobileNav)}
                    </Details>
                  )
                })}
              </nav>
            )}
          </div>
        </header>
      </>
    )
  }
}

export default Header
