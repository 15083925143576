import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Header from './Header'
import Footer from './Footer'
import { makeHeaderProps, makeFooterProps } from './layoutData'
import ExternalUrlModal from '../../General/ExternalUrlModal/ExternalUrlModal'

const query = graphql`
  query {
    allPrismicGlobal(filter: { lang: { eq: "en-us" } }) {
      nodes {
        lang
        data {
          notification_label {
            text
          }
          notification_bar_message {
            text
          }
          notification_bar_link_text {
            text
          }
          notification_bar_link {
            url
          }
        }
      }
    }
    allPrismicGlobalNavigation(filter: { lang: { eq: "en-us" } }) {
      nodes {
        lang
        data {
          follow {
            follow_us_text {
              text
            }
            twitter_url {
              url
            }
            linkedin_url {
              url
            }
            telegram_url {
              url
            }
            facebook_url {
              url
            }
            medium_url {
              url
            }
            youtube_url {
              url
            }
            algorand_community_url {
              url
            }
            reddit_url {
              url
            }
            discord_url {
              url
            }
          }
          copyright_text {
            text
          }
          algorand_official_sites_text {
            text
          }
          algorand_official_sites_link {
            url
          }
          body {
            ... on PrismicGlobalNavigationBodyMenuSection {
              primary {
                menu_label {
                  text
                }
                menu_link {
                  url
                }
                menu_highlight
                is_green
              }
              items {
                sub_menu_item_label {
                  text
                }
                sub_menu_item_link {
                  url
                }
              }
            }
          }
          footer_body {
            ... on PrismicGlobalNavigationFooterBodyMenuSection {
              primary {
                footer_menu_section_title {
                  text
                }
              }
              items {
                footer_menu_section_item_title {
                  text
                }
                footer_menu_section_item_link {
                  url
                  target
                }
              }
            }
          }
        }
      }
    }
  }
`

const Layout = ({ children, locale }) => {
  const data = useStaticQuery(query)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [currentExternalUrl, setCurrentExternalUrl] = useState('')
  useEffect(() => {
    window.onload = () => {
      const externalLinks = [...document.querySelectorAll('a')].filter(
        (link) => {
          if (link.hostname === '') return false

          let newHostname =
            link.hostname && link.hostname.includes('www')
              ? link.hostname.replace('www.', '')
              : link.hostname
          return newHostname !== location.hostname
        }
      )
      if (externalLinks) {
        externalLinks.forEach((link) => {
          link.addEventListener('click', (e) => {
            e.preventDefault()
            setModalIsOpen(true)
            setCurrentExternalUrl(link.href)
          })
        })
      }
    }
  })

  return (
    <div className="min-h-screen flex flex-col">
      <Header {...makeHeaderProps(data, locale)} />
      {children}
      <ExternalUrlModal
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        url={currentExternalUrl}
      />
      <Footer {...makeFooterProps(data, locale)} />
    </div>
  )
}

export default Layout
