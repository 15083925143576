import React from 'react'
import SmartLink from '../../General/SmartLink'

const footerWrapClasses = `
  footer-gradient
  relative
  z-10
  mt-auto
`

const footerInteriorClasses = `
  max-w-5xl
  mx-auto
  lg:px-9
  lg:pt-8
  lg:pb-8
  px-5
  pt-6
  pb-6
`

const greyTextClasses = `
  block
  font-sans
  font-bold
  no-underline
  text-grey
  text-sm
`

const legalSectionClasses = `
  flex
  justify-center
  items-center
  flex-col
  lg:flex-row
`

const bottomSectionClasses = `
  flex
  items-center
  self-center
  flex-col
  lg:self-start
`

const Footer = ({ copyrightText, official }) => {
  return (
    <footer className={footerWrapClasses} id={`site-footer`}>
      <div className={footerInteriorClasses}>
        <div className={bottomSectionClasses}>
          <div className={legalSectionClasses}>
            <p className={greyTextClasses}>
              ©{new Date().getFullYear()} {copyrightText} |{' '}
              <SmartLink
                className="text-white no-underline hover:underline"
                to={official.route}
              >
                {official.text}
              </SmartLink>
              &nbsp;|&nbsp;
              <SmartLink
                className="text-white no-underline hover:underline"
                to={`/transparency`}
              >
                Transparency
              </SmartLink>
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
